<template>
  <div class="wrapper_modal" @click.stop="hideModal">
    <div @click.stop class="modal">
      <img
        class="close-btn"
        src="@/assets/icons/buttons/x-circle.svg"
        @click="hideModal"
      />
      <h2 class="header global_text">Пользовательское соглашение</h2>
      <div class="content">
        <ol>
          <li>
            <span class="uppercase">Определение понятий</span>
            <p>
              В тексте настоящего Соглашения нижеизложенным терминам дано
              следующее значение:
            </p>
            <p>
              <strong>"Компания", "Мы", "Наш", "Нас", "Нами"</strong> или
              какие-либо другие схожие производные (в зависимости от контекста)
              означает следующее лицо: TEAM "12 PEOPLE", адрес местонахождения:
              Российская Федерация, г.Москва (включая, его филиалы и
              представительства как на территории Российской Федерации, так и за
              ее пределами, а также какие-либо другие лица, созданные в
              результате реорганизации Компании), которые являются владельцами
              Приложения или им управляют.
            </p>
            <p>
              <strong>"Контент приложения"</strong> означает все объекты,
              размещенные Компанией и/или третьими лицами (с разрешения
              Компании) в Приложении, в том числе элементы дизайна, текст,
              графические изображения, иллюстрации, виртуальные объекты, видео,
              персонажи, темы, имена персонажей, сюжетные линии, диалоги,
              настройки, звуковые эффекты, программы, музыка, звуки, информация,
              уведомления и какие-либо другие схожие объекты, их подборки или
              комбинации.
            </p>
            <p>
              <strong>"Обновления"</strong> означает программный патч или
              программный пакет для Приложения, который время от времени
              выпускается Компанией, предлагается для бесплатной загрузки
              Пользователями, которые уже используют Приложение, и направлен на
              фиксацию неработающих функций Приложения, устранение багов
              (ошибок) в работе Приложения или внедрение небольших программных
              компонентов для обеспечения большей безопасности и совместимости
              Приложения с устройствами.
            </p>
            <p>
              <strong>"Платформа"</strong> означает облачную платформу, на
              которой Компания разместила Приложение для его последующего
              скачивания Пользователем. Настоящее Приложение доступно для
              скачивания из Google Play.
            </p>
            <p>
              <strong>"ПО приложения"</strong> означает программное обеспечение,
              разработанное Компанией (и/или третьими лицами по поручению
              Компании) для Приложения, включая, однако не ограничиваясь, весь
              софт, скрипты, коды (HTML коды), программы и тп.
            </p>
            <p>
              <strong>"Пользователь", "Вы", "Ваш", "Вас", "Вами"</strong> или
              какие-либо другие схожие производные (в зависимости от контекста)
              означает лицо, которое (1) пользуется Приложением и получило
              доступ к Услугам; и (2) дало свое согласие на соблюдение правил
              пользования Приложением, изложенных в тексте данного Соглашения,
              путем проставления специальной галочки при открытии Приложения.
            </p>
            <p>
              <strong>"Приложение"</strong> означает следующее приложение:
              Twelve, которое Пользователь загружает через Платформу на смартфон
              или другое устройство.
            </p>
            <p>
              <strong>"Услуги"</strong> означает совокупно Контент приложения и
              ПО приложения.
            </p>
            <p>
              <strong>"In-app покупка"</strong> означает получение Пользователем
              за плату дополнительных возможностей и/или функционала для
              Приложения и/или приобретение каких-либо виртуальных товаров/услуг
              в рамках Приложения.
            </p>
          </li>
          <li>
            <span class="uppercase">Присоединение к соглашению</span>
            <p><strong>Общие положения</strong></p>
            <ol>
              <li>
                Настоящее Пользовательское соглашение (далее
                <strong>"Соглашение"</strong>) определяет правила и порядок
                использования Приложения, права и обязанности Пользователей, а
                также регулирует поведение Пользователей при получении доступа к
                Приложению и Услугам.
              </li>
              <li>
                Пользователь принимает условия данного Соглашения путем
                проставления специальной галочки в отведенном поле при загрузке
                Приложения на смартфон или другое устройство.
              </li>
              <li>
                Данное Соглашение является обязательным для его сторон (т.е. для
                Компании и Пользователя). Уступка Пользователем своих прав по
                данному Соглашению возможна только после получения
                предварительного письменного согласия от Компании.
              </li>
              <li>
                Если Вы являетесь законным представителем (родителем, опекуном)
                несовершеннолетнего Пользователя, в таком случае Вы
                автоматически соглашаетесь от своего имени и от имени такого
                несовершеннолетнего Пользователя на условия данного Соглашения.
              </li>
              <p><strong>Предупреждение для пользователей</strong></p>
              <li>
                Если Пользователь осуществляет доступ к Приложению и/или его
                Услугам через Платформу или социальные сети (к примеру,
                Facebook, Vkontakte, Одноклассники), в таком случае Пользователь
                автоматически считается таким, который принял условия
                пользовательского соглашения такой Платформы или социальной
                сети.
              </li>
              <li>
                Пользователь признает, что его соглашение с провайдером
                мобильной сети (далее <strong>"Провайдер"</strong>) будет
                применяться к использованию Пользователем данного Приложения.
                Пользователь также признает, что Провайдер может взимать с
                Пользователя время от времени плату за услуги передачи данных
                при использовании отдельных функций Приложения, а также
                какие-либо иные сборы и платежи, возникающие в связи с такой
                передачей и за которые Пользователь обязуется нести
                ответственность. Если Пользователь не является плательщиком
                счетов Провайдера на смартфоне или другом устройстве,
                используемом для доступа в Приложение, предполагается, что такой
                Пользователь получил разрешение от плательщика счета на
                использование Приложения.
              </li>
              <li>
                Пользователь является единственным ответственным лицом за
                проверку и контроль соответствия устанавливаемого Приложения
                техническим особенностям/возможностям смартфона или иного
                устройства и/или других ограничений, которые могут быть
                применимы к Пользователю и/или его смартфону или другому
                устройству третьими лицами, включая Интернет провайдера.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Пользователи приложения</span>
            <p><strong>Общие критерии и возраст</strong></p>
            <ol>
              <li>
                Для использования Приложения Пользователи должны соответствовать
                следующим критериям (совокупно):<br />
                <b>(1)</b> быть не младше 14-лет; и<br />
                <b>(2)</b> не быть ограниченными в праве доступа к Приложению и
                Услугам на основании решения суда, вступившего в законную силу,
                или в случаях, предусмотренных действующим законодательством или
                условиями данного Соглашения.
              </li>
              <p><strong>Создание личного аккаунта</strong></p>
              <li>
                Пользование Услугами возможно как Пользователями, которые прошли
                процедуру создания личного аккаунт (кабинета), так и
                Пользователями, которые отказались от такого создания.
                Незарегистрированные Пользователи не имеют доступа к следующим
                Услугам:<br />
                <em>Доступ к полному контенту игры (уровни, карты)</em>
              </li>
              <li>
                Для снятия указанных ограничений и получения полного доступа
                Пользователю необходимо создать свой личный аккаунт (кабинет).
                По результатам завершения регистрации Пользователь получает
                уникальный логин и пароль.
              </li>
              <li>
                Действие данного Соглашения распространяется одинаково в полном
                объеме (без каких-либо исключений) как на Пользователей с личным
                аккаунтом (кабинетом), так и без него.
              </li>
              <li>
                Для создания личного аккаунта (кабинета) Пользователю необходимо
                предоставить следующею информацию о себе:
                <em>Адрес электронной почты;</em><br />
                <em>при регистрации через аккаунт Google - данные аккаунта</em>
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Интеллектуальная собственность</span>
            <p><strong>Пользовательская лицензия</strong></p>
            <ol>
              <li>
                Пользователь получает неисключительную, без права передачи и
                сублицензии, <span class="uppercase">только</span> для личного
                (некоммерческого) пользования лицензию на Услуги (далее
                <strong>"Пользовательская лицензия"</strong>). Пользователь
                берет на себя обязательство не использовать Услуги в каких-либо
                других целях. Пользователь получает указанную Пользовательскую
                лицензию <span class="uppercase">только</span> при условии
                соблюдения <span class="uppercase">всех</span> условий данного
                Соглашения.
              </li>
              <li>
                Пользовательская лицензия прекращает действовать автоматически
                при удалении Приложения со смартфона или другого устройства
                Пользователя. Ничто в тексте настоящего Соглашения не должно
                быть истолковано как право на получение Пользователем какой-либо
                иной лицензии на использование объектов интеллектуальной
                собственности, принадлежащих Компании или находящихся в ее
                владении, кроме той, которая предоставлена выше.
              </li>
              <p><strong>Интеллектуальная собственность компании</strong></p>
              <li>
                Компании принадлежат все без исключения права собственности,
                включая имущественные права интеллектуальной собственности, на
                весь Контент приложения, а также ПО приложения. ПО приложения и
                Контент приложения защищены авторским правом в порядке,
                предусмотренном действующим гражданским законодательством
                Российской Федерации, а также международными договорами и
                конвенциями в области защиты объектов интеллектуальной
                собственности.
              </li>
              <li>
                <span class="uppercase">Пользователям запрещено</span>
                копировать, воспроизводить, модифицировать, компилировать,
                распространять, отображать в какой-либо форме, публиковать,
                скачивать, передавать, продавать (полностью или частично),
                отчуждать любым возможным способом за плату или безвозмездно,
                передавать в сублицензию, каким-либо способом распространять или
                использовать Контент приложения и ПО приложения, кроме случаев,
                когда такие действия
                <span class="uppercase">прямо</span> разрешены условиями данного
                Соглашения или действующим законодательством Российской
                Федерации.
              </li>
              <li>
                Ничто в тексте данного Соглашения не может быть истолковано как
                передача Пользователю каких-либо исключительных прав на Контент
                приложения (полностью или в отдельной части) и/или ПО
                приложения.
              </li>
              <li>
                Компания владеет всеми правами в отношении торговых марок,
                коммерческих (бизнес) наименований, брендов, логотипов,
                зарегистрированных на ее имя (далее
                <strong>"Торговые марки"</strong>). Такие Торговые марки
                защищаются действующим законодательством и НИЧТО в тексте
                данного Соглашения не может быть истолковано как передача
                какой-либо лицензии Пользователю на использование таких Торговых
                марок.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Порядок работы с приложением</span>
            <p><strong>Общие положения</strong></p>
            <ol>
              <li>
                Пользователь обязуется соблюдать следующие правила при работе с
                Приложением:<br />
                <b>(1)</b> соблюдать все обязательства, взятые на себя
                Пользователем в связи с присоединением к данному Соглашению;
                и<br />
                <b>(2)</b> предоставлять достоверные о себе данные для создания
                личного аккаунта (кабинета); и<br />
                <b>(3)</b> не выдавать себя за какое-либо другое лицо, включая,
                однако не ограничиваясь, не предоставлять какие-либо данные
                третьих лиц (без получения от них прямого, предварительного и
                осознанного согласия) для создания личного аккаунта (кабинета);
                и<br />
                <b>(4)</b> информировать Компанию о хищении логинов, паролей или
                какихлибо других ключей доступа Пользователя к личному аккаунту
                (кабинету); и<br />
                <b>(5)</b> не предоставлять третьим лицам доступ к своему
                аккаунту (кабинету) и/или логины, пароли или другие ключи
                доступа; и<br />
                <b>(6)</b> не совершать какие-либо действия (с использованием
                средств автоматизации или без таких средств), направленные на
                сбор каких-либо персональных данных других Пользователей; и<br />
                <b>(7)</b> не предпринимать какие-либо действия и не помогать
                третьим лицам в совершении действий, направленных на подрыв
                работы Приложения и/или Услуг, включая, однако не ограничиваясь,
                <b>(а)</b> загружать вирусы или вредоносный код;
                <b>(б)</b> совершать действия, которые могут привести к
                отключению Приложения и/или Услуг, к нарушению нормальной работы
                Приложения или его программного обеспечения, или к ухудшению
                внешнего вида Приложения и/или Контента приложения.<br />
                <b>(8)</b> не предпринимать какие-либо иные действия, которые
                являются незаконными, мошенническими, дискриминационными или
                вводящими в заблуждение.
              </li>
              <li>
                Услуги время от времени могут включать в себя какие-либо
                виртуальные объекты, которые в течение игры может
                "зарабатывать"/получать Пользователь. Пользователь настоящим
                считается должным образом уведомлен, что такие виртуальные
                объекты <b>(1)</b> существуют исключительно в рамках Приложения
                и не предназначены для использования в реальной жизни; и
                <b>(2)</b> не имеют никакой другой ценности/стоимости кроме той,
                которую они имеют в игре; и <b>(3)</b> Компания может в любой
                момент без какого-либо предварительного уведомления удалить,
                обнулить, изменить и/или модифицировать их без предварительного
                уведомления и/или предоставления какой-либо компенсации
                Пользователю.
              </li>
              <li>
                Компания освобождает себя от какой-либо ответственности за вред,
                убытки, упущенную выгоду, потерю репутации и/или какой-либо иной
                ущерб, причиненный Пользователю потерей, проигрышем, удалением,
                обнулением, исчезновением каких-либо виртуальных объектов
                вследствие <b>(1)</b> технической неисправности или сбоя в
                работе Приложения, или <b>(2)</b> установления Обновлений, или
                <b>(3)</b> удаления аккаунта Пользователя в связи с нарушением
                им условий данного Соглашения, или <b>(4)</b> удаления
                Пользователем Приложения на смартфоне или другом устройстве.
              </li>
              <p><strong>Отзывы о работе приложения</strong></p>
              <li>
                Каждый Пользователь время от времени имеет право (но не
                обязанность) оставлять или направлять свои идеи, отзывы,
                предложения или проекты, направленные на улучшение работы
                Приложения или качества предоставляемых Услуг. Такие отзывы
                могут быть направлены Пользователем следующим способом:<br />
                <i>Отправить письмо по след. адресу:</i><br />
                <em>twelvepeoplegame@gmail.com</em>
              </li>
              <li>
                В случае направления такой идеи, отзыва, предложения или проекта
                Пользователь автоматически предоставляет нам неисключительную,
                не требующую лицензионных отчислений, действующую по всему миру
                лицензию с правом передачи и выдачи сублицензий на хранение,
                использование, распространение, изменение, запуск, копирование,
                публичное исполнение или показ, перевод Ваших идей, отзывов,
                предложений или проектов, а также создание производных работ на
                их основе.
              </li>
              <li>
                Любая такая информация, предоставляемая Компании, автоматически
                признается неконфиденциальной.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Размещение рекламы в приложении</span>
            <p><strong>Размещение рекламы компанией</strong></p>
            <ol>
              <li>
                Компания имеет право время от времени размещать какие-либо
                рекламные или маркетинговые материалы.
              </li>
              <p><strong>Размещение рекламы третьими лицами</strong></p>
              <li>
                Контент приложения может содержать ссылки на веб-сайты третьих
                лиц и/или рекламные или маркетинговые материалы о
                товарах/услугах, предоставляемых такими третьими лицами (далее
                <p><strong>"Реклама третьих лиц"</strong></p>
                ). Компания не берет на себя какую-либо ответственность
                <b>(1)</b> за содержание рекламы третьих лиц, а также за
                наличие, качество и безопасность продвигаемых товаров/услуг в
                такой рекламе; и <b>(2)</b> за какие-либо убытки, потери или
                ущерб, понесенные или причиненные пользователю в результате
                прочтения им такой рекламы, использования им продвигаемых в
                рекламе третьих лиц товаров/услуг.
              </li>
              <li>
                В случае перехода на другой сайт через размещенную Рекламу
                третьих лиц, Компания не может гарантировать, что такой веб-сайт
                является безопасным для Пользователя и/или его компьютера. Ни
                что в тексте данного Соглашения не должно быть истолковано как
                заверение, поощрение, рекомендация или побуждение Пользователя
                воспользоваться Рекламой третьих лиц, посетить какие-либо сайты
                третьих лиц, а также попробовать, приобрести, воспользоваться
                какими-либо товарами/услугами третьих лиц.
              </li>
              <li>
                Вопросы, связанные с защитой персональных данных Пользователей
                при использовании ими Рекламы третьих лиц, регулируются
                Политикой конфиденциальности приложения.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Проведение оплаты через приложение</span>
            <ol>
              <li>
                Приложение не предоставляет возможность покупки каких-либо
                товаров/услуг через него.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Совершение In-App покупок</span>
            <ol>
              <li>
                Совершение каких-либо In-app покупок внутри Приложения не
                предусмотрено.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Доступ к приложению</span>
            <p><strong>Общие положения</strong></p>
            <ol>
              <li>
                Компания оставляет за собой право изменить или модифицировать
                Контент приложения в любой момент без указания причины, по
                своему собственному усмотрению и без необходимости уведомления
                об этом Пользователя. Компания также оставляет за собой право
                модифицировать, прервать или прекратить работу части или всего
                Приложения в любой момент без какого-либо дополнительного
                уведомления. В связи с изложенным Компания не берет на себя
                какую-либо ответственность перед Пользователями или третьими
                лицами за любые произведенные изменения, модификации, удаления,
                упразднения, прекращения или перерывы в работе Приложения.
              </li>
              <li>
                Компания не гарантирует, что Приложение и Услуги будут
                доступными Пользователю постоянно. Время от времени Компания
                может сталкиваться с проблемами в работе оборудования, ПО
                приложения или другими неполадками, в связи с чем Компании может
                потребоваться время на изучение и устранение таких проблем.
                Такое устранение ошибок может привести к сбоям, задержкам или
                ошибкам в работе Приложения. Компания оставляет за собой право
                изменять, пересматривать, обновлять, приостанавливать,
                прекращать или иным образом изменять Приложение в любое время
                или по любой причине без предварительного уведомления.
                Пользователь соглашается с тем, что Компания не несет
                ответственности за какие-либо убытки, ущерб или неудобства,
                вызванные неспособностью Пользователя получить доступ или
                использовать Приложение во время простоя или прекращения работы
                Приложения. Ничто в условиях данного Соглашения не будет
                истолковано как обязывающее Нас поддерживать работу Приложения
                без остановок и сбоев.
              </li>
              <p><strong>Предоставление обновлений</strong></p>
              <li>
                Компания время от времени может предоставлять Обновления и
                требовать их инсталляции на смартфон или другое устройство
                Пользователя. В данном случае Пользователь является единственным
                ответственным лицом за установление Обновлений и несет полную
                ответственность за какие-либо убытки, потери, ущерб или
                упущенную выгоду, причиненную Пользователю несвоевременным
                установлением Обновлений или неустановлением их вовсе,
                несовместимостью установленных Обновлений и смартфона/другого
                устройства. Компания не предоставляет какую-либо техническую
                поддержку или интернет соединение Пользователю для возможности
                получения доступа к Услугам и/или их Обновлениям.
              </li>
              <p><strong>Удаление аккаунта</strong></p>
              <li>
                Пользователь имеет право в любой момент прекратить использование
                Приложения посредством удаления его со своего смартфона или
                другого устройства.
              </li>
              <li>
                В случае <b>(1)</b> нарушения Пользователем условий настоящего
                Соглашения или когда у Компании есть достаточные основания
                полагать, что такие нарушения были допущены; и/или
                <b>(2)</b> нарушения прав интеллектуальной собственности
                Компании, других Пользователей или третьих лиц; и/или
                <b>(3)</b> совершения действий, которые являются
                противозаконными, нарушают права и интересы Компании, других
                Пользователей или третьих лиц или подрывают работу Приложения
                или возможность использования Приложения другими Пользователями;
                и/или <b>(4)</b> Пользователь использует Услуги или Приложение
                таким образом, что это может повлечь юридическую ответственность
                для Компании в будущем; и/или <b>(5)</b> если этого требует
                действующее законодательство или компетентный государственный
                орган, Компания имеет право без предварительного уведомления в
                любой момент прекратить (остановить) доступ Пользователя к
                Приложению и Услугам посредством удаления его аккаунта.<br />
                При наступлении обстоятельств, изложенных в предыдущем абзаце,
                Пользователю запрещено создавать какие-либо другие аккаунты в
                Приложении в будущем.
              </li>
              <li>
                Во всех случаях удаления аккаунта Пользователя или удаления
                Приложения со смартфона или другого устройства Пользователя все
                данные и информация, размещенные Пользователем в аккаунте и/или
                связанные с ним, будут безвозвратно удалены. Компания не берет
                на себя какую-либо ответственность за удаление таких данных и
                информации, а также за какойлибо вред, ущерб, убытки или
                упущенную выгоду, причиненную Пользователю таким удалением и/или
                отсутствием доступа к Услугам в целом.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Задать вопрос</span>
            <ol>
              <li>
                Если у Вас есть вопросы касательно условий настоящего Соглашения
                или порядка/способа их исполнения, Вы можете адресовать нам свой
                вопрос следующим способом:<br />
                <i>Отправить письмо по след. адресу:</i><br />
                <em>twelvepeoplegame@gmail.com</em>
              </li>
              <li>
                Сотрудники и представители Компании обязуются предпринять все
                возможные усилия для дачи ответа на Ваш запрос в течение
                разумного периода времени.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Ответственность</span>
            <ol>
              <li>
                Ни при каких обстоятельствах компания или ее представители не
                несут ответственность перед пользователем или перед любыми
                третьими лицами: <b>(1)</b> за любой косвенный, случайный,
                неумышленный ущерб, включая упущенную выгоду или потерянные
                данные, вред чести, достоинству или деловой репутации, вызванный
                использованием приложения, услуг или иных материалов, к которым
                пользователь или иные лица получили доступ с помощью приложения,
                даже если компания предупреждала или указывала на возможность
                такого вреда; и <b>(2)</b> за действия других пользователей, за
                размещаемый пользователями пользовательский контент, за
                товары/услуги, предоставляемые третьими лицами или другими
                пользователями (даже если доступ к ним был предоставлен через
                наше приложение), к которым пользователь получил доступ; и
                <b>(3)</b> в случаях прямо предусмотренных условиями настоящего
                Соглашения или нормой действующего законодательства.
              </li>
              <li>
                Наша ответственность за все что угодно, связанное с
                использованием Приложения и/или Услуг, ограничивается настолько
                сильно, насколько это допускается действующим законодательством.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Порядок разрешения споров</span>
            <ol>
              <li>
                В случае возникновения любых споров или разногласий, связанных с
                исполнением настоящего Соглашения, Пользователь и Компания
                приложат все усилия для их разрешения путем проведения
                переговоров между ними. В случае, если споры не будут разрешены
                путем переговоров, споры подлежат разрешению в порядке,
                установленном действующим законодательством Российской
                Федерации.
              </li>
            </ol>
          </li>
          <li>
            <span class="uppercase">Заключительные положения</span>
            <ol>
              <li>
                Мы можем время от времени пересматривать, дополнять или изменять
                условия данного Соглашения. Такие изменения, как правило, не
                являются ретроспективными. В случае такого пересмотра,
                дополнения или изменения, Компания обязуется уведомить
                Пользователей за 10 календарных дней до момента вступления в
                силу таких изменений посредством публикации соответствующей
                информации непосредственно в самом Приложении. Если после
                проведенных изменений или дополнений в тексте Соглашения
                Пользователь продолжает пользоваться Приложением, это значит,
                что он ознакомлен с изменениями или дополнениями и их принял в
                полном объеме без каких-либо возражений.
              </li>
              <li>
                Если иное прямо не указано в положениях настоящего Соглашения
                или прямо не вытекает из норм действующего законодательства, к
                условиям настоящего Договора применяется материальное право
                Российской Федерации.
              </li>
              <li>
                Неотъемлемой частью настоящего Соглашение является Политика
                конфиденциальности приложения.
              </li>
              <li>
                Если одно или несколько условий данного Соглашения утратило свою
                юридическую силу или признано недействительным согласно
                действующему законодательству, остальные условия Соглашения не
                теряют своей силы и продолжают действовать так, словно
                признанного недействительным или утратившим юридическую силу
                условия не существовало вовсе.
              </li>
              <li>
                Доступ к Приложению и его Услугам предоставляется Пользователю
                "как есть", Мы не обещаем, не гарантируем, не подразумеваем, что
                Услуги и Приложение могут подходить или не подходить Вашим
                потребностям, целям, ожиданиям, и в связи с чем не гарантируем
                какого-либо специфического результата или последствия в
                результате использования Вами Приложения и его Услуг.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyModal",
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  methods: {
    hideModal() {
      this.$emit("close-modal")
    },
  },
  emits: ["close-modal"],
}
</script>

<style scoped>
.wrapper_modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: hsla(216, 23%, 9%, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.header {
  margin-bottom: 3rem;
  background: var(--six-gold-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.content {
  overflow-y: scroll;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-feature-settings: "calt" off;
  color: #fceabc;
}

.modal {
  position: relative;
  height: 80%;
  max-width: 335px;
  width: 100%;
  background: var(--third-dark-gradient);
  border-radius: 18px;
  padding: 45px 22px;
  display: flex;
  flex-direction: column;
}

p,
ol li {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.uppercase {
  text-transform: uppercase;
}

ol {
  list-style: none;
  counter-reset: li;
}

li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #272f41;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #fceabc;
  border-radius: 5px;
}
</style>
