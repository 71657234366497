<template>
  <div class="hand">
    <draggable
      v-model="draggableHand"
      item-key="id"
      @start="onDragStart($event)"
      @end="onDragEnd($event)"
      class="hand-list"
    >
      <template #item="{ element, index }">
        <card-item
          :card="element"
          :index="index"
          class="card_in_hand"
          :style="{ '--custom-z-index': 10 - index }"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import CardItem from "@/components/Cards/CardItem"
export default {
  name: "hand-comp",
  components: { CardItem, draggable },
  props: {
    hand: {
      required: true,
      type: Array,
    },
    field: {
      required: true,
      type: Array,
    },
    enemy_leader: {
      required: true,
      type: Object,
    },
  },
  computed: {
    draggableHand: {
      get() {
        return this.hand
      },
      set(val) {
        console.log(val)
      },
    },
  },
  methods: {
    chose_player_card(card) {
      this.$emit("chose_player_card", card) // передаём card по эмиту
    },
    // берем список дивов под картой в руке, достаем ту, у которой есть id, там cardName_fieldIndex, так находим index
    get_card(divs) {
      for (let i = 0; i < divs.length; i++) {
        if (divs[i].id) return divs[i].id
      }
    },
    onDragStart(event) {
      console.log("ПОТЯНУЛИ ЗА КАРТУ")
      const elems = document.elementsFromPoint(
        event.originalEvent.clientX,
        event.originalEvent.clientY
      )
      const id = this.get_card(elems)
      console.log("КАРТА В РУКЕ", id)
      if (!id) return
      const index = parseInt(id.slice(id.indexOf("_") + 1))
      console.log("ИНДЕКС КАРТЫ В РУКЕ", index, this.hand[index].name)
      this.$emit("chose_player_card", this.hand[index])
    },
    onDragEnd(event) {
      const event_type = event?.originalEvent?.type // если мы с компа, то там есть этот параметр

      if (event_type === "dragend") {
        console.log("МЫ С КОМПА!!!!")
        const x = event?.originalEvent?.clientX
        const y = event?.originalEvent?.clientY
        if (!x || !y) return
        const elems = document.elementsFromPoint(x, y)
        this.get_target(elems)
      } else {
        console.log("МЫ С ТЕЛЕФОНА!!!")
        const x = event?.originalEvent?.changedTouches?.[0].clientX
        const y = event?.originalEvent?.changedTouches?.[0].clientY
        if (!x || !y) return
        const elems = document.elementsFromPoint(x, y)
        this.get_target(elems)
      }
    },
    get_target(elems) {
      let elem = null
      elems.forEach(el => {
        if (
          el.className === "card-enemy-component" ||
          el.className === "enemy-leader"
        ) {
          console.log(el.className)
          elem = el
        }
      })
      this.target_emit(elem)
    },
    target_emit(elem) {
      const id = elem?.id
      console.log("ВРАГ", id)
      if (!id) return
      if (id.includes("enemy_leader")) {
        console.log("ЭТО ЛИДЕР ВРАГА")
        this.$emit("target_enemy_leader")
        return
      }
      const index = parseInt(id.slice(id.indexOf("_") + 1)) // card.name_index - вот поэтому ищем _ +1, чтоб индекс поля
      console.log("ИНДЕКС КЛЕТКИ ПОЛЯ ВРАГА", index)
      this.$emit("target_enemy", this.field[index])
    },
  },
  emits: ["chose_player_card", "target_enemy", "target_enemy_leader"],
}
</script>

<style scoped>
.hand {
  width: 99%;
  margin-top: 2%;
}

.hand-list {
  display: flex;
  justify-content: space-around;
  flex-shrink: 1;
  margin: 0 10px;
}

.card_in_hand {
  z-index: var(--custom-z-index);
  width: 26%;
  margin-left: -10%;
  margin-right: -10%;
  border-radius: 2px;
}

.card_in_hand:first-child {
  margin-left: -5%;
}

.card_in_hand:last-child {
  margin-right: -5%;
}
.card_in_hand:hover {
  margin-top: -2%;
  z-index: 999999;
}
</style>
