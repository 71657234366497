<template>
  <div class="destroy_ability"></div>
</template>

<script>
export default {
  name: "DestroyAbility",
}
</script>

<style scoped>
.destroy_ability {
  position: absolute;
  right: 5px;
  top: 30%;
  width: 12px;
  height: 20px;
  background-image: url("~@/assets/icons/card/destroy.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
