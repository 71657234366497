<template>
  <button class="draw-btn">ДРО</button>
</template>

<script>
export default {
  name: "draw-comp",
}
</script>

<style scoped>
.draw-btn {
  height: 70%;
  width: 98%;
  border: solid 1px green;
  margin-bottom: 1%;
  margin-top: 1%;
  position: relative;
  border-radius: 50%;
}
</style>
