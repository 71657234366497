<template>
  <div>ЗАГРУЗКА</div>
</template>

<script>
export default {
  name: "LoadingPage",
}
</script>

<style scoped></style>
