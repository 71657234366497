<template>
  <div class="row-attack-ability"></div>
</template>

<script>
export default {}
</script>

<style scoped>
.row-attack-ability {
  position: absolute;
  right: 5px;
  top: 30%;
  width: 12px;
  height: 20px;
  background-image: url("~@/assets/icons/card/row_attack.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
