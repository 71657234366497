<template>
  <div class="damage-icon-wrapper">
    <div class="damage-count">
      {{ damage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    damage: {
      // type: Number, // здесь нельзя Number, потому что в момент урона строка приходит
      require: true,
    },
  },
}
</script>

<style scoped>
.damage-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  transform: rotate(-45deg);
}

.damage-count {
  position: absolute;
  transform: rotate(45deg);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: whitesmoke;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.damage-count::before {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  background-image: url("~@/assets/icons/card/sword.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
