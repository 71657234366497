<template>
  <modal-window v-touch:swipe="close_self">
    <button-close @close_self="close_self" />

    <!--Имя у карты есть всегда-->
    <h2>{{ card.name }}</h2>

    <!--Карта игрока, или карта лидера врагов!-->
    <div
      class="card-ui"
      :style="[border(card)]"
      v-if="!forEnemy || forEnemyLeader"
    >
      <card-ui v-bind="$props" />
    </div>
    <!--А это соответственно карта врага, у неё есть card.move-->
    <div class="card-ui" :style="[border(card)]" v-if="forEnemy">
      <enemy-ui :enemy="card" />
    </div>

    <card-descriptions :card="card" :forEnemy="forEnemy" />

    <!--Блок кнопок милл, крафт (ТОЛЬКО ДЛЯ ДЕКБИЛДЕРА!!!-->
    <div class="mill_craft_block" v-if="deckbuilder">
      <div class="divb" v-if="!bonus">
        <button class="global_text btn btn-mill" @click="mill">
          Уничтожить
        </button>
        <card-count-triangle
          :count="count"
          :card-color="background_color_triangle(card.color)"
        />
        <button class="global_text btn btn-craft" @click="craft">
          Создать
        </button>
      </div>
      <div class="divb" v-if="bonus">
        <button class="bonus_count">У вас {{ count }}</button>
      </div>
    </div>
    <yesno-modal
      v-if="show_yesno_mill"
      :item_price="resource_value"
      @confirm="confirm_mill"
      @cancel="cancel"
    />
    <yesno-modal
      v-if="show_yesno_craft"
      is_craft
      :item_price="resource_value"
      @confirm="confirm_craft"
      @cancel="cancel"
    />
  </modal-window>
</template>

<script>
import {
  border_for_card,
  background_color,
  border_leader,
  background_color_leader,
  background_color_hp,
} from "@/logic/border_styles"
import CardCountTriangle from "@/components/UI/CardsUI/Cards/CardCountTriangle"
import ButtonClose from "@/components/UI/Buttons/ButtonClose"
import ModalWindow from "@/components/ModalWindows/ModalWindow"
import YesnoModal from "@/components/ModalWindows/YesnoModal"
import CardUi from "@/components/Cards/CardUi"
import EnemyUi from "@/components/Cards/EnemyUi"
import CardDescriptions from "@/components/Cards/CardDescriptions"
export default {
  name: "card-modal",
  components: {
    CardCountTriangle,
    CardDescriptions,
    EnemyUi,
    CardUi,
    ModalWindow,
    ButtonClose,
    YesnoModal,
  },
  props: {
    // брать границу карты как для лидеров
    is_leader: {
      type: Boolean,
      default: false,
    },
    user_card: {
      // объект противника по индексу поля
      type: Object,
      default() {
        return null
      },
    },
    card: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
    },
    hp_needed: {
      // hp только для декбилдера, для игры не нужно оно
      type: Boolean,
      default: false,
    },
    bonus: {
      //этот пропс используется для страницы BonusPage
      type: Boolean,
      default: false,
      required: false,
    },
    deckbuilder: {
      type: Boolean,
      default: false,
    },
    // отображать описание для врага или нет
    forEnemy: {
      type: Boolean,
      required: false,
      default: false,
    },
    // отображать описание для лидера врагов или нет
    forEnemyLeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show_passive: false,
      show_yesno_mill: false,
      show_yesno_craft: false,
      resource_value: 0,
    }
  },
  methods: {
    close_self() {
      this.$emit("close_card_modal")
    },
    border(card) {
      return this.is_leader ? border_leader(card) : border_for_card(card)
    },
    background_color(e) {
      return background_color(e)
    },
    background_color_triangle(color) {
      return this.is_leader
        ? background_color_leader(this.card.faction)
        : background_color_hp(color)
    },
    cancel() {
      this.show_yesno_mill = false
      this.show_yesno_craft = false
    },
    async mill() {
      let can_mill = await this.$store.dispatch("calculate_value", {
        card: this.card,
        process: "mill",
        count: this.count,
      })
      if (!can_mill) return
      this.resource_value = can_mill
      this.show_yesno_mill = true
    },
    async craft() {
      let can_craft = await this.$store.dispatch("calculate_value", {
        card: this.card,
        process: "craft",
        count: this.count,
      })
      if (!can_craft) return
      this.resource_value = can_craft
      this.show_yesno_craft = true
    },
    async confirm_mill() {
      this.show_yesno_mill = false
      let result = await this.$store.dispatch("pay_resource", {
        scraps: this.$store.getters["resource"].scraps + this.resource_value,
      })
      if (result) await this.$store.dispatch("mill_card_action", this.user_card)
    },
    async confirm_craft() {
      this.show_yesno_craft = false
      let result = await this.$store.dispatch("pay_resource", {
        scraps: this.$store.getters["resource"].scraps + this.resource_value,
      })
      if (result)
        await this.$store.dispatch("craft_card_action", this.user_card)
    },
  },
  emits: ["close_card_modal"],
}
</script>

<style scoped>
div {
  color: white;
}
.card-ui {
  position: relative;
  margin: 0 auto;
  width: 85%;
  box-shadow: -4px 0 4px rgb(0 0 0 / 50%);
}
.card-ui::before {
  content: "";
  display: block;
  padding-top: 143%;
}
.divb {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.btn {
  width: 48%;
  height: 3rem;
  background: linear-gradient(
    180deg,
    #1d252d -21.82%,
    rgba(0, 0, 0, 0.13) 44.55%,
    #282d33 109.53%
  );
  font-size: 16px;
  border-style: solid;
  border-color: hsl(44, 94%, 67%);
  border-radius: 6px;
}
.btn-mill {
  border-width: 1px 1px 0 0;
  color: hsl(0, 76%, 47%);
}
.btn-craft {
  border-width: 1px 0 0 1px;
  color: hsl(112, 81%, 53%);
}
.bonus_count {
  width: 90%;
  height: 100%;
}
</style>
