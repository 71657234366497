<template>
  <div class="shield">
    <span> &#128737; </span>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.shield {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

span {
  font-size: 30pt;
  color: white;
}
</style>
